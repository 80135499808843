<script lang="ts" setup>
import { useField } from 'vee-validate'
import type { CheckboxProps as Props } from './FCheckbox.vue'

const props = defineProps<Props>()
const { value, errorMessage } = useField(props.name!)
</script>

<template>
  <FCheckbox
    v-bind="props"
    v-model="value"
    :error="errorMessage"
  >
    <slot />
  </FCheckbox>
</template>
